import 'slick-carousel'

const Slideshow = {
  $el: $('.bg-slideshow'),
  init() {
    const _ = this

    _.$el.slick({
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      fade: true,
      speed: 500,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false
    })
  }
}

export default Slideshow
