const $body = $('body');

const LangSwitcher = {
	init: function () {
		$('a[href="#lang-switcher"]').on('click', function(e) {
	        e.preventDefault();
			$(this).parent('.module-language').toggleClass('open');
		});
		
		$(document).on('click', function (e) {
			if ( $(e.target).closest(".module-language").length === 0 ) {
				$('.module-language').removeClass('open');
			} 
		});
	}
}

export default LangSwitcher
