
const DetailsSizes = {
  $togglesizes: $('[data-toggle-sizes="details-sizes"]'),
  init() {
    const _ = this
//     _.$togglesizes.on('click', function(e) {

	$(document).on('click', 'a.sizes-btn', function(e) {

      const $self = $(this)
      const $item = $self.parents('.list-item-sizes')
      const $list = $item.parents('.list-sizes')
      const $details = $(`<div class="${$self.data('details-sizes')} list-sizes-details"><div class="${$self.data('details-sizes')}-inner list-sizes-details-inner">${$item.find('.list-item-sizes-details').html()}</div></div>`)

      const initialPos = $item.offset().top
      let $nextItem = $item.next()
      let nextPos = 0

/*
console.log(initialPos)
console.log($nextItem)
*/

      if ($nextItem.length) {
	      
// 	      alert('1');
	      
        nextPos = $nextItem.offset().top

        while (nextPos === initialPos) {
          $nextItem = $nextItem.next()
          try {
            nextPos = $nextItem.offset().top
          } catch {
            showSizesDetails($list.children().last())
            return
          }
        }
        showSizesDetails($nextItem.prev())
        return
      } else {
	      
// 	      alert('2');
	      
        showSizesDetails($list.children().last())
        return
      }

      function showSizesDetails($target) {
        const $currentDetails = $list.find('.list-sizes-details')
        if ($item.hasClass('active')) {
          $item.removeClass('active')
          $currentDetails.slideUp(300)
        } else {
          $list.find('.active').removeClass('active')
          $item.addClass('active')

          if ($currentDetails.length) {
            if (nextPos === $currentDetails.offset().top) {
              $list.find('.list-sizes-details').fadeOut(300, function() {
                $(this).remove()
                $details.insertAfter($target).fadeIn(300)
              })
            } else {
              $list.find('.list-sizes-details').slideUp(300, function() {
                $(this).remove()
                $details.insertAfter($target).slideDown(300)
//                 alert('2')
              })
            }
          } else {
            $details.insertAfter($target).slideDown(300)
          }
        }
      }

    })
  }
}

export default DetailsSizes
