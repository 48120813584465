import 'jquery-mapael'
import 'jquery-mapael/js/maps/world_countries.js'
import 'mapael-maps/poland/poland.js'

const Location = {
  $world: $('.world-map'),
  $poland: $('.poland-map'),
  $empty: $('.map-empty'),
  $tabs: $('#map-tabs'),
  $worldItems: $('[data-map="world"] [data-latlon]'),
  $polandItems: $('[data-map="poland"] [data-latlon]'),
  activeArea: null,
  init: function() {
    const _ = this

    _.$tabs.on('tabs-update', () => {
      let newData = {
        areas: {}
      }

      newData.areas[_.activeArea] = {
        attrs: {
          fill: '#D5D5D5'
        }
      }
      _.$world.trigger('update', [{ mapOptions: newData }])
      _.$poland.trigger('update', [{ mapOptions: newData }])

      $('[data-latlon]').show()
    })

    const getPoints = function($items) {
      const points = {}
      $items.each(function(index) {
        const $self = $(this)

        const obj = {
          latitude: Number($self.data('latlon').split(',')[0]),
          longitude: Number($self.data('latlon').split(',')[1])
        }

        points[`place-${index}`] = obj
      })

      return points
    }

    const defaultPlot = {
      attrs: {
        fill: '#000000'
      },
      attrsHover: {
        fill: '#000000'
      },
      size: 3
    }

    const defaultArea = {
      attrs: {
        fill: '#D5D5D5',
        stroke: '#FFFFFF',
        cursor: 'pointer'
      },
      attrsHover: {
        fill: '#afafaf',
        stroke: '#FFFFFF',
        animDuration: 100
      },
      eventHandlers: {
        click: function(e, id, mapElem, textElem) {
          const $map = $($(e.delegateTarget))
          const $list = $($map.data('list'))
          const $items = $list.find('[data-latlon]')

const typeMapclassName = e.delegateTarget.className;
const typeMap = typeMapclassName.substring( 0, typeMapclassName.lastIndexOf("-") );

// console.log( id );

          let count = 0

          let newData = {
            areas: {}
          }

          if (_.activeArea === id) {
            newData.areas[id] = {
              attrs: {
                fill: '#D5D5D5'
              }
            }

            $items.show()
            $list.find('.map-empty').hide()

            _.activeArea = null
          } else {
            newData.areas[_.activeArea] = {
              attrs: {
                fill: '#D5D5D5'
              }
            }
            newData.areas[id] = {
              attrs: {
                fill: '#909090'
              }
            }
            
$items.each(function(index) {
  const $self = $(this)
  if (typeMap === 'world' && id === $self.data('filter') || typeMap === 'poland' && id === $self.data('filter-poland')) {
    $self.show()
    count++
  } else {
    $self.hide()
  }
})


if (typeMap === 'world') {
// 	console.log('world')
	_.$world.trigger('zoom', {
        area: id,
        areaMargin: 10
    })
}

/*
            $items.each(function(index) {
              const $self = $(this)
              if (id === $self.data('filter')) {
                $self.show()
                count++
              } else {
                $self.hide()
              }
            })
*/

            if (count === 0) {
              $list.find('.map-empty').show()
            } else {
              $list.find('.map-empty').hide()
            }
            _.activeArea = id
          }
          $map.trigger('update', [{ mapOptions: newData }])
        }
      }
    }
    
    const zoom = {
		enabled: true,
		mousewheel: false,
		step: 0.5
    }

    _.$world.mapael({
      map: {
        defaultPlot,
        defaultArea,
        name: 'world_countries',
        zoom,
        afterInit(container, paper, areas, plots, options) {
          _.$world
            .parents('.tab-world')
            .removeClass('active')
            .removeAttr('style')
        }
      },
      plots: getPoints(_.$worldItems)
    })

    defaultArea.attrs['stroke-width'] = 2
    defaultPlot.size = 7

    _.$poland.mapael({
      map: {
        defaultPlot,
        defaultArea,
        name: 'poland',
        zoom
      },
      plots: getPoints(_.$polandItems)
    })
  }
}

export default Location
