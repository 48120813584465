
const Textures = {
  $toggle: $('[data-toggle="details"]'),
  init() {
    const _ = this

    _.$toggle.on('click', function(e) {

      const $self = $(this)
      const $item = $self.parents('.list-item')
      const $list = $item.parents('.list')
      const $details = $(`<div class="${$self.data('details')} list-details"><div class="${$self.data('details')}-inner list-details-inner">${$item.find('.list-item-details').html()}</div></div>`)
      const initialPos = $item.offset().top
      let $nextItem = $item.next()
      let nextPos = 0

      if ($nextItem.length) {
        nextPos = $nextItem.offset().top

        while (nextPos === initialPos) {
          $nextItem = $nextItem.next()
          try {
            nextPos = $nextItem.offset().top
          } catch {
            showDetails($list.children().last())
            return
          }
        }
        showDetails($nextItem.prev())
        return
      } else {
        showDetails($list.children().last())
        return
      }

      function showDetails($target) {
        const $currentDetails = $list.find('.list-details')
        if ($item.hasClass('active')) {
          $item.removeClass('active')
          $currentDetails.slideUp(300)
        } else {
          $list.find('.active').removeClass('active')
          $item.addClass('active')

          if ($currentDetails.length) {
            if (nextPos === $currentDetails.offset().top) {
              $list.find('.list-details').fadeOut(300, function() {
                $(this).remove()
                $details.insertAfter($target).fadeIn(300)
                selectSize()
                slickInit()
              })
            } else {
              $list.find('.list-details').slideUp(300, function() {
                $(this).remove()
                $details.insertAfter($target).slideDown(300)
                selectSize()
                slickInit()
              })
            }
          } else {
            $details.insertAfter($target).slideDown(300)
            selectSize()
            slickInit()
          }
        }
      }

		function slickInit() {
			const $listDetails = $list.find('.list-details')
			const $slickSlider = $listDetails.find('.slick-slider')
			const slickSliderID = 'slick-slider-' + Math.random().toString(36).substr(2, 9)
			$slickSlider.addClass( slickSliderID )
			$('.' + slickSliderID).slick()
		}
		
		function selectSize() {
			const $currentDetails2 = $list.find('.list-details')
			const $item_sizes_first = $currentDetails2.find('.sizes-item.first')
			$item_sizes_first.find('a.sizes-btn').trigger("click");
		}
		
    })
  }
}

export default Textures
