const $body = $('body')

const Search = {
  $toggle: $('[data-toggle="search"]'),
  $module: $('.module-search'),
  init() {
    const _ = this

    _.$module.on('click', function(e) {
      e.stopPropagation()
    })

    _.$toggle.on('click', function(e) {
      $body.addClass('search-open')
      e.stopPropagation()
    })
  },
  hide() {
    $body.removeClass('search-open')
  }
}

export default Search
