import Images from './modules/images'
import Search from './modules/search'
import SliderTaglines from './modules/slider-taglines'
import Animations from './modules/animations'
import Navigation from './modules/navigation'
import Scroll from './modules/scroll'
import Variations from './modules/variations'
import Colors from './modules/colors'
import Details from './modules/details'
import Tabs from './modules/tabs'
import Locations from './modules/locations'
import Slideshow from './modules/slideshow'
import ImgToSvg from './modules/imgtosvg'

import DetailsSizes from './modules/details-sizes'
import LangSwitcher from './modules/language'

import lightbox2 from 'lightbox2/dist/js/lightbox.min.js'


// import SlickSlider from './modules/slickslider'

$(() => {
  Animations.init()
  Images.init()
  SliderTaglines.init()
  Search.init()
  Navigation.init()
  Variations.init()
  Colors.init()
  Details.init()
  Tabs.init()
  Locations.init()
  Slideshow.init()
  ImgToSvg.init()
  DetailsSizes.init()
  LangSwitcher.init()
  //   SlickSlider.init()
})

$(function(){
//	$('.composLightbox').lightbox2
});

$(document).on('click', () => {
  Search.hide()
})

let scrolled = $(window).scrollTop()
$(window).on('scroll', () => {
  scrolled = $(window).scrollTop()

  Scroll.handle(scrolled)
  Animations.handle(scrolled, Scroll.direction)
})

// Animations.init()
// Navigation.init()
// SliderTaglines.init()
// Form.init()
