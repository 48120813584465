const Tabs = {
  $toggler: $('[data-toggle="tab"]'),
  $pane: $('.tabs-pane'),
  init: function() {
    const _ = this

    _.$toggler.on('click', function() {
      const $self = $(this)
      const $target = $($self.attr('href'))
      const $navs = $self.parents('.nav')
      const $tabs = $($navs.data('tabs'))

      $tabs
        .find('.tabs-pane.active')
        .fadeOut(150, function() {
          $(this).trigger('tabs-update')
          $target
            .fadeIn(150)
            .addClass('active')
            .trigger('tabs:show')
        })
        .removeClass('active')
      $navs.find('.active').removeClass('active')
      $self.addClass('active')

      return false
    })

    _.$pane.each(function() {
      const $self = $(this)

      if ($self.hasClass('active')) {
        $self.show(0)
        $(`[href="#${$self.attr('id')}"]`).addClass('active')
      }
    })
  }
}

export default Tabs
