const $body = $('body')

const Navigation = {
  init: function() {
    const _ = this
    _.scrollers = $('[data-local-scroll]')
    _.navToggle = $('[data-toggle="navigation"]')

    _.scrollers.find('a').on('click', function() {
      const $self = $(this)
      const id = $self.attr('href')

      if (id !== '#') {
        _.scrollTo(id, $self.data('target-pull'))
        $self.blur()
        return false
      } else {
        return false
      }
    })

    // Navigation Toggle
    _.navToggle.on('click', function() {
      $(this).toggleClass('open')
      $body.toggleClass('navigation-open')
      return false
    })
  },
  scrollTo: function(id, pull) {
    const _ = this
    const $target = $(id)

    if ($target.length) {
      console.log(pull)
      var offsetTop = pull ? $target.offset().top - _.scrollOffset * 1.5 : $target.offset().top - _.scrollOffset + 1

      $('html, body').animate(
        {
          scrollTop: offsetTop
        },
        600
      )
    } else {
      console.log('Wrong href...')
    }
  },
  hide: function() {
    var _ = this
    $body.removeClass('navigation-open')
    _.navToggle.removeClass('open')
  }
}

export default Navigation
