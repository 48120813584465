import 'slick-carousel'

const Variations = {
  $el: $('.variations-item'),
  init() {
    const _ = this

    _.$el.each(function() {
      const $self = $(this)
      const $slider = $($self.find('.variations-item-slider'))
      const $colors = $($self.find('.variations-item-colors'))

      $slider.on('init', function() {
        $colors
          .children()
          .eq(0)
          .addClass('active')
      })

      $slider.slick({
        arrows: false,
        dots: false,
        fade: true,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: true
      })

      $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $colors.find('.active').removeClass('active')
        $colors
          .children()
          .eq(nextSlide)
          .addClass('active')
      })

      $colors.children().on('click', function() {
        const $self = $(this)

        $slider.slick('goTo', $self.index())
        return false
      })
    })
  }
}

export default Variations
