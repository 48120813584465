import 'slick-carousel'

const Slider = {
  $el: $('.section-content-slider'),
  init() {
    const _ = this

    _.$el.each(function() {
      const $contents = $(this)
      const $images = $($contents.data('images'))

      $contents.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        speed: 500,
        pauseOnFocus: false,
        pauseOnHover: true,
        asNavFor: $images
      })

      $images.slick({
        arrows: false,
        dots: false,
        fade: true,
        speed: 500,
        draggable: false,
        touchMove: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        asNavFor: $contents
      })
    })
  }
}

export default Slider
