import 'slick-carousel'

const Colors = {
  $el: $('.colors-carousel'),
  init() {
    const _ = this

    _.$el.slick({
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 568,
          settings: {
            arrows: true,
            slidesToShow: 1
          }
        }
      ]
    })
  }
}

export default Colors
